/**
 * Renders a banner section with a slanted background image
 * has option of heading, text, videobutton, and
 * tagbar
 */

import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { Heading1 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import sanityImage from '../../../utils/sanityImage';
import {
  breakpoints,
  colors,
  fontSizes,
  lineHeights,
  pageDimensions,
  sectionSettings,
  unitConverter as uc,
} from '../../../styles/base';
import TagBar from '../../molecules/TagBar/TagBar';
import TextBlock from '../../molecules/TextBlock/TextBlock';
import PageWrapper from '../PageWrapper/PageWrapper';
import VideoButton from '../../atoms/Buttons/VideoButton/VideoButton';
import FixedSideBar from '../FixedSideBar/FixedSideBar';
import FixedSideBarV1 from '../FixedSideBarV1/FixedSideBarV1';

const SlantBanner = ({
  backgroundImage,
  children,
  fixedSideBar,
  fixedSideBarV1,
  paddingBottom,
  paddingTop,
  productCategoryEntity,
  sidebarText,
  tagBar,
  templateSpecificStyles,
  textBlock: { align, ...textBlockProps },
  videoButton,
}) => {
  const industryPage = fixedSideBar && fixedSideBar.logos;

  const textBlockCss = css`
    position: relative;
    z-index: 3;
    max-width: ${uc(industryPage ? '605px' : '700px')};
    margin-top: ${sectionSettings[paddingTop]};
    margin-right: ${align === 'center' ? 'auto' : 0};
    margin-bottom: ${sectionSettings[paddingBottom]};
    margin-left: ${align === 'center' ? 'auto' : 0};
    padding: ${uc('200px 0 200px 20px')};
    line-height: ${lineHeights.copy};
    text-align: ${align};

    @media (${breakpoints.smallDesktopMax}) {
      display: block;
      width: 100%;
      max-width: ${uc('730px')};
      margin-right: auto;
      margin-left: auto;
      padding: ${uc('200px 10px 40px 10px')};
      text-align: center;

      div.textBlockCss {
        padding: 0;
        background-color: transparent;

        div.buttons {
          justify-content: center;
        }
      }
    }

    @media (${breakpoints.smallDesktopMax}) {
      div.buttons {
        justify-content: center;
      }
    }

    .text-block {
      ${Heading1} {
        color: ${colors.white};
        font-size: ${fontSizes.forty};
        white-space: ${industryPage ? 'nowrap' : 'normal'};

        @media (${breakpoints.mobile}) {
          font-size: ${fontSizes.twentyEight};
        }
      }

      ${Text} {
        color: ${colors.white};
        font-size: ${fontSizes.eighteen};

        @media (${breakpoints.mobile}) {
          font-size: ${fontSizes.sixteen};
        }
      }

      @media (${breakpoints.largeTablet}) {
        background: none;
      }
    }
  `;

  const sansTagBarCss = css`
    margin-bottom: ${uc(industryPage ? '117.6px' : '0')};
  `;

  const backgroundCss = css`
    position: relative;
    width: 100%;
    margin-top: ${uc('-100px')};
    overflow: hidden;
    background: no-repeat top center;
    background-image: ${typeof backgroundImage === 'string'
      ? `url(${backgroundImage})`
      : `url(${sanityImage(backgroundImage)
          .auto('format')
          .width(pageDimensions.rawLargeDesktopWidth)
          .fit('max')
          .url()});`};
    background-size: cover;

    /* slant the bottom of the background */
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 57.5%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 57.5%, 0 100%);

    @media (${breakpoints.smallDesktopMax}) {
      clip-path: none;
    }

    /* add blue overlay */
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      background-color: ${colors.primary};
      background-image: ${industryPage
        ? 'none'
        : 'linear-gradient(#0047ba00, #0047ba), radial-gradient(circle at 100% 0%, #0ba1d2 0%,  #0ba1d2 10%, #0047ba 50%, #0047ba)'};
      opacity: ${industryPage ? 0.4 : 0.8};
      content: '';
    }

    @media (${breakpoints.smallDesktopMax}) {
      padding-bottom: ${uc('40px')};
      clip-path: none;

      &::after {
        z-index: 0;
      }
    }
    ${templateSpecificStyles}
  `;

  // calculates maxWidth so FixedSideBar will be
  // positioned properly without overlapping TagBar
  const halfPageWidth = pageWidth => {
    const pageWidthInPx = parseInt(uc(pageWidth).split('px')[0], 10);
    return uc(`${pageWidthInPx / 2}px`);
  };

  const fixedSideBarCss = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    max-width: ${halfPageWidth(pageDimensions.desktopWidth)};
    margin-left: 50%;

    @media (${breakpoints.smallDesktopMax}) {
      position: relative;
      width: 95%;
      max-width: 95%;
      margin: auto;
    }
  `;

  function renderFixedSideBar() {
    if (fixedSideBarV1 && Object.keys(fixedSideBarV1).length > 0) {
      return (
        <PageWrapper css={fixedSideBarCss}>
          <FixedSideBarV1
            productCategoryEntity={productCategoryEntity}
            sidebarText={sidebarText}
            {...fixedSideBarV1}
          />
        </PageWrapper>
      );
    }
    if (
      fixedSideBar &&
      Object.keys(fixedSideBar).length > 0 &&
      fixedSideBar.hide !== true
    ) {
      return (
        <PageWrapper css={fixedSideBarCss}>
          <FixedSideBar {...fixedSideBar} />
        </PageWrapper>
      );
    }
    return '';
  }

  return (
    <section className="slant-banner">
      <div css={backgroundCss}>
        <PageWrapper className="page-wrapper">
          <div css={textBlockCss}>
            <div css={sansTagBarCss}>
              <TextBlock
                align={align}
                className="text-block"
                HeadingType={Heading1}
                {...textBlockProps}
              />
              {videoButton && (
                <VideoButton
                  color={videoButton.color}
                  className="video-button"
                  {...videoButton}
                >
                  {videoButton.buttonText}
                </VideoButton>
              )}
            </div>
            {tagBar && <TagBar {...tagBar} />}
          </div>
        </PageWrapper>
      </div>
      {renderFixedSideBar()}
      {children}
    </section>
  );
};

SlantBanner.propTypes = {
  backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  children: PropTypes.node,
  fixedSideBar: PropTypes.shape({ logos: [], hide: PropTypes.bool }),
  fixedSideBarV1: PropTypes.shape({ logos: [] }),
  paddingBottom: PropTypes.string,
  paddingTop: PropTypes.string,
  productCategoryEntity: PropTypes.arrayOf(PropTypes.shape({})),
  sidebarText: PropTypes.string,
  tagBar: PropTypes.shape({}),
  templateSpecificStyles: PropTypes.shape({}),
  textBlock: PropTypes.shape({ align: PropTypes.string }),
  videoButton: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
};

SlantBanner.defaultProps = {
  backgroundImage: null,
  children: null,
  fixedSideBar: {},
  fixedSideBarV1: {},
  paddingBottom: sectionSettings.medium,
  paddingTop: sectionSettings.medium,
  productCategoryEntity: [],
  sidebarText: '',
  tagBar: null,
  templateSpecificStyles: {},
  textBlock: {},
  videoButton: false,
};

export default SlantBanner;
